import { httpBatchLink } from '@trpc/client';
import superjson from 'superjson';
import { type TRPCClientInit, createTRPCClient } from 'trpc-sveltekit';

import type { AppRouter } from '../server/router';
import type { ExtensionConfig } from './';

const TRPC_ENDPOINT = '/trpc';

export type CustomTRPCClientInit = TRPCClientInit & {
	data?: (Record<string, unknown> & (ExtensionConfig | undefined)) | null;
};

/**
 * The tRPC browser client.
 *
 * It is configured differently depending on whether we are in the webapp or extension.
 * The extension is configured to allow CORS requests to the webapp, with cookies/credentials.
 *
 * Ideally, clients would be defined in each app, but this is not possible because
 * we need to use the client in our shared components.
 *
 * Accordingly, the best solution is to define a single client here (in a shared package),
 * and use it across all of our other projects and packages (webapp, extension, etc.).
 *
 * @see https://github.com/icflorescu/trpc-sveltekit#define-a-helper-function-to-easily-use-the-trpc-client-in-your-pages.
 * @see https://trpc.io/docs/client/cors.
 */

let browserClient: ReturnType<typeof createTRPCClient<AppRouter>>;
export function trpc(init?: CustomTRPCClientInit) {
	const isBrowser = typeof window !== 'undefined';
	if (isBrowser && browserClient) return browserClient;

	const isExtensionEnv = import.meta.env.VITE_IS_EXTENSION === 'true';
	const isExtensionConfigDefined = !!init?.data?.extensionConfig;
	if (isExtensionEnv && !isExtensionConfigDefined) {
		// Code running in a Chrome extension (content script, background page, etc.)
		throw new Error(
			'init.data.extensionConfig MUST be true when using trpc in the browser extension. Most likely, either: (1) you have called `trpc(event)...` in the +layout.ts file in the extension (without passing extensionConfig), or (2) you have not returned the extensionConfig from the +layout.ts file correctly, such that it is available when accessing the `$page` store.'
		);
	}

	const isExtension = init?.data?.extensionConfig?.isExtension;
	const webappUrl = init?.data?.extensionConfig?.webappUrl;

	if (isExtension && !webappUrl) {
		throw new Error('webappUrl is required when using trpc in the browser extension');
	}

	const client = createTRPCClient<AppRouter>(
		isExtension
			? // Use website link if in extension.
				{
					links: [
						httpBatchLink({
							// Ensure that cookies are sent to the server,
							// so we can use session-based auth.
							// See https://trpc.io/docs/client/cors.
							fetch(url, options) {
								return fetch(url, {
									...options,
									credentials: 'include'
								});
							},
							url: webappUrl + TRPC_ENDPOINT
						})
					],
					transformer: superjson
				}
			: //   Else use the default setup.
				// See https://github.com/icflorescu/trpc-sveltekit.
				{
					init,
					transformer: superjson
				}
	);

	if (isBrowser) browserClient = client;

	return client;
}
